import Vue from 'vue'
import Vuex from 'vuex'

import shopOwner from './modules/shop_owner';
import shop from './modules/shop';
import advert from './modules/advert';
import customer from './modules/customer';
import template from './modules/template';
import category from './modules/category';
import product from './modules/product';
import accessory from './modules/accessory';
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules: {
    auth,
    shop,
    shopOwner,
    customer,
    template,
    category,
    product,
    accessory,
    advert
  }
})
