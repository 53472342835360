import axiosOg, * as others from "axios";
// import jwt from "jsonwebtoken";

function getAccessToken() {
  const unParsedToken = localStorage.getItem("batelew-token");

  if (!unParsedToken) {
    return null;
  }

  const token = JSON.parse(unParsedToken);

  // const tokenData = jwt.decode(token)

  return token.token;
}

let axios = axiosOg.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.VUE_APP_PROD_BASE_API_URL
      : process.env.VUE_APP_PROD_BASE_API_URL_LOCAL
      ? process.env.VUE_APP_PROD_BASE_API_URL_LOCAL
      : process.env.VUE_APP_DEV_BASE_API_URL,
  headers: {
    Authorization: "Bearer " + getAccessToken(),
  },
});

axios.defaults.withCredentials = true;

export { axios as default};
