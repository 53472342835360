<template>
<div class="hold-transition login-page">
  <div class="login-box">
        <div class="login-logo">
            <a href="../../index2.html"><b>Batelew</b> Shop Owner</a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">Register</p>

                <form @submit.prevent="submit()" action="" method="post">
                    <div class="input-group mb-3">
                        <input  v-model="formData.name" required name="name" type="text" class="form-control" placeholder="Name">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-user"></span>
                            </div>
                        </div>
                    </div>
                    <span class="text-danger" v-if="errors.name">
                            {{ errors.name[0] }}
                    </span> 
                    <div class="input-group mb-3">
                        <input  v-model="formData.email" required name="email" type="email" class="form-control" placeholder="Email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-user"></span>
                            </div>
                        </div>
                        
                        
                    </div>
                    <span class="text-danger" v-if="errors.email">
                            {{ errors.email[0] }}
                    </span> 
                    <div class="input-group mb-3">
                        <input v-model="formData.password" required type="password" name="password" class="form-control" placeholder="Password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <br> 
                    </div>
                    <span class="text-danger" v-if="errors.password">
                            {{ errors.password[0] }}
                    </span>
                    <div class="input-group mb-3">
                        <input v-model="formData.password_confirmation" required type="password" name="password_confirmation" class="form-control" placeholder="Confirm Password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    
                    </div>
                    <span class="text-danger" v-if="errors.password_confirmation">
                            {{ errors.password_confirmation[0] }}
                    </span> 
                    <div class="row">
                        <div class="col-8">
                            <div class="icheck-primary">
                                <input type="checkbox" id="remember">
                                <label for="remember">
                                    Remember Me
                                </label>
                            </div>
                        </div>
                        <!-- /.col -->
                        <div class="col-4">
                            <button type="submit" class="btn btn-primary btn-block">Register</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>

            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
</div>
  
</template>

<script>
// @ is an alias to /src
import {mapActions} from 'vuex'

export default {
  name: 'Register',
  data(){
    return {
        formData:{
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
        },
        errors: []
        
      
    }
  },
  components: {
    
  },
  methods:{
    ...mapActions(['registerShopOwner']),
    submit(){
      this.registerShopOwner(this.formData).then(()=>{
          this.$router.push({name: "Login"})
      }).catch(error => {
          if(error.response.status === 422){
              this.errors = error.response.data.errors;
          }
      })
    }

  }
}
</script>
