<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Shop Templates List</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="/elite_admin_home">Home</a>
              </li>
              <li class="breadcrumb-item active">Template List</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Template List
                  <span class="badge badge-info right">{{
                    filteredTemplates.length
                  }}</span>
                </h3>

                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <div class="input-group-append">
                      <button
                        v-if="listPlus"
                        v-on:click="listPlus = false"
                        class="btn btn-outline-primary"
                      >
                        <i class="fas fa-list"></i>
                      </button>
                      <button
                        v-else
                        v-on:click="listPlus = true"
                        type="submit"
                        class="btn btn-outline-primary"
                      >
                        <i class="fas fa-image"></i>
                      </button>
                    </div>
                    <input
                      type="text"
                      v-model="searchString"
                      name="table_search"
                      class="form-control float-right"
                      placeholder="Search"
                    />

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-default">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div v-if="listPlus" class="card-body pb-0">
                <div class="row d-flex align-items-stretch">
                  <div
                    v-for="template in filteredTemplates"
                    v-bind:key="template.id"
                    class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch"
                  >
                    <div class="card bg-light">
                      <div class="card-header text-muted border-bottom-0"></div>
                      <div class="card-body pt-0">
                        <div class="row">
                          <div class="col-7">
                            <h2 class="lead">
                              <b>{{ template.name }}</b>
                            </h2>
                            <p class="text-muted text-sm">
                              <b>Description: </b>
                              <span v-if="template.description"
                                >{{
                                  template.description.substring(0, 50)
                                }}...</span
                              >
                            </p>
                            <ul class="ml-4 mb-0 fa-ul text-muted">
                              <li class="small">
                                <span class="fa-li"
                                  ><i class="fas fa-lg fa-image"></i
                                ></span>
                                Images:
                                <span v-if="template.images">{{
                                  template.images.length
                                }}</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-5 text-center">
                            <img
                              v-if="template.images[0]"
                              :src="serverUrl + template.images[0].thumbUrl"
                              alt=""
                              class="img-circle img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="text-right">
                          <router-link
                            :to="'/show/template/' + template.id"
                            class="btn btn-sm btn-primary"
                          >
                            <i class="fas fa-eye"></i> View
                          </router-link>
                          <router-link
                            :to="'/edit/template/' + template.id"
                            href="#"
                            class="btn btn-sm bg-teal"
                          >
                            <i class="fas fa-edit"></i> Edit
                          </router-link>
                          <a
                            href="#"
                            v-on:click="removeTemplate(template.id)"
                            class="btn btn-sm btn-danger"
                          >
                            <i class="fas fa-trash"></i> Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->
              </div>
              <div v-else class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Designed By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="template in filteredTemplates"
                      v-bind:key="template.id"
                    >
                      <td>
                        <router-link :to="'/show/template/' + template.id">{{
                          template.name
                        }}</router-link>
                      </td>
                      <td>{{ template.price }}</td>
                      <td>{{ template.designed_by }}</td>
                      <td>
                        <router-link
                          :to="'/edit/template/' + template.id"
                          class="btn btn-sm bg-teal"
                        >
                          <i class="fas fa-edit"></i
                        ></router-link>
                        <button
                          class="btn btn-sm btn-danger"
                          v-on:click="removeTemplate(template.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TemplateList",
  data: function() {
    return {
      searchString: "",
      listPlus: false,
    };
  },
  computed: {
    ...mapGetters(["templates", "serverUrl"]),
    filteredTemplates() {
      return this.templates.filter((owner) => {
        return !owner.name
          .toUpperCase()
          .search(this.searchString.toUpperCase());
      });
    },
  },
  created() {
    this.fetchTemplates();
  },
  methods: {
    ...mapActions(["fetchTemplates", "deleteTemplate"]),
    getDate(date) {
      return moment(date)
        .add(3, "hour")
        .fromNow();
    },
    async removeTemplate(id) {
      bootbox.confirm(
        "Are you sure to delete the template and all its related data?",
        async (result) => {
          if (result) {
            await this.deleteTemplate(id)
              .then((res) => {
                this.$toastr.s("Template removed successfully.");
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
