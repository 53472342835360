import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

import ShopOwnerList from "../components/ShopOwnerList";
import ShopList from "../components/ShopList";
import CustomerList from "../components/CustomerList";
import TemplateList from "../components/TemplateList";
import ShopOwnerShow from "../components/ShopOwnerShow";
import ShopShow from "../components/ShopShow";
import TemplateShow from "../components/TemplateShow";
import TemplateForm from "../components/TemplateForm";
import ShopOwnerForm from "../components/ShopOwnerForm";
import ShopForm from "../components/ShopForm";
import CategoryShow from "../components/CategoryShow";
import ProductShow from "../components/ProductShow";
import auth from "../api/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: ShopList,
    children: [],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/list/shop-owner",
    component: ShopOwnerList,
    props: true,
  },
  {
    path: "/list/shop",
    component: ShopList,
    props: true,
  },
  {
    path: "/list/customer",
    component: CustomerList,
    props: true,
  },
  {
    path: "/list/template",
    component: TemplateList,
    props: true,
  },
  {
    path: "/show/shop-owner/:id",
    component: ShopOwnerShow,
    props: true,
  },
  {
    path: "/show/shop/:id",
    component: ShopShow,
    props: true,
  },
  {
    path: "/show/template/:id",
    component: TemplateShow,
    props: true,
  },
  {
    path: "/add/shop-owner",
    component: ShopOwnerForm,
    props: true,
  },
  {
    path: "/add/shop",
    component: ShopForm,
    props: true,
  },
  {
    path: "/add/template",
    component: TemplateForm,
    props: true,
  },
  {
    path: "/edit/template/:id",
    component: TemplateForm,
    props: true,
  },
  {
    path: "/edit/shop/:id",
    component: ShopForm,
    props: true,
  },
  {
    path: "/edit/shop-owner/:id",
    component: ShopOwnerForm,
    props: true,
  },
  {
    path: "/show/category/:id",
    component: CategoryShow,
    props: true,
  },
  {
    path: "/show/product/:id",
    component: ProductShow,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "Login" &&
    to.name !== "Register" &&
    localStorage.getItem("batelew-token") === null
  ) {
    next({ name: "Login" });
  } else if (
    (to.name === "Login" || to.name === "Register") &&
    localStorage.getItem("batelew-token")
  ) {
    next("/home");
  } else {
    next();
  }
});

export default router;
