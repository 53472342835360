<template>
  <div v-if="template.id">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Shop Template</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <a href="/elite_admin_home">Home</a>
                </li>
                <li class="breadcrumb-item active">
                  <router-link :to="'/list/template'"
                    >Template List</router-link
                  >
                </li>
                <li class="breadcrumb-item active">{{ template.name }}</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5">
              <!-- Widget: user widget style 2 -->
              <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-dark">
                  <div class="widget-user-image">
                    <img
                      v-if="template.images[0]"
                      :src="serverUrl + template.images[0].storageUrl"
                      class="img-circle elevation-2"
                      alt="User Avatar"
                    />
                    <img
                      v-else
                      class="img-circle elevation-2"
                      src="/img/store.png"
                      alt="User Avatar"
                    />
                  </div>
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">
                    {{ template.name }}
                    <span class="float-right">
                      <router-link
                        :to="'/edit/template/' + template.id"
                        class="btn btn-sm btn-secondary"
                      >
                        <i class="fas fa-edit"></i
                      ></router-link>
                      <button
                        v-on:click="removeTemplate(template.id)"
                        class="btn btn-sm btn-outline-danger"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </span>
                  </h3>
                </div>
                <div class="card-footer p-0">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Id</span>
                        <span class="float-right">{{ template.id }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Name</span>
                        <span class="float-right">{{ template.name }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Price</span>
                        <span class="float-right">{{ template.price }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Designed By</span>
                        <span class="float-right">{{
                          template.designed_by
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Description</span>
                        <span class="float-right">{{
                          template.description
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Shops</span>
                        <span class="float-right badge bg-primary"
                          ><span v-if="template.abouts">{{
                            template.abouts.length
                          }}</span
                          ><span v-else>0</span></span
                        >
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Date Added</span>
                        <span class="float-right ">{{
                          getDate(template.created_at)
                        }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.widget-user -->
            </div>
            <div v-if="template.images" class="col-12 col-sm-7">
              <h3 class="d-inline-block d-sm-none">{{ template.name }}</h3>
              <div class="col-12">
                <img
                  v-if="template.images[0]"
                  :src="serverUrl + template.images[0].storageUrl"
                  id="product-image"
                  class="product-image"
                  alt="Product Image"
                />
              </div>
              <div class="col-12 product-image-thumbs">
                <div
                  v-for="(image, index) in template.images"
                  v-bind:key="index"
                >
                  <button
                    class="btn btn-outline-danger btn-sm"
                    v-on:click="removeImage(image.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                  <div
                    class="product-image-thumb"
                    :class="image.id === activeImageId ? 'bg-dark' : ''"
                    v-on:click="setImage(image)"
                  >
                    <img
                      :src="serverUrl + image.storageUrl"
                      alt="Product Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ShopList :fetched-shops="template.shops"></ShopList>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ShopList from "./ShopList";

export default {
  name: "ShopShow",
  data() {
    return {
      activeImageId: null,
    };
  },
  components: {
    ShopList,
  },
  computed: {
    ...mapGetters([
      "templates",
      "template",
      "getDate",
      "shopOwner",
      "serverUrl",
    ]),
  },
  props: {
    id: {
      required: true,
    },
  },
  async created() {
    await this.fetchTemplate(this.id);

    //console.log(this.getTemplate(this.id));
  },
  methods: {
    ...mapActions(["fetchTemplate", "deleteTemplate", "deleteTemplateImage"]),

    setImage(image) {
      document.getElementById("product-image").src =
        this.serverUrl + image.storageUrl;
      this.activeImageId = image.id;
    },

    async removeTemplate(id) {
      bootbox.confirm(
        "Are you sure to delete the Template and all its related data?",
        async (result) => {
          if (result) {
            await this.deleteTemplate(id)
              .then((res) => {
                this.$toastr.s("Template removed successfully.");
                this.$router.push("/list/template/").catch((err) => {});
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },

    async removeImage(id) {
      bootbox.confirm("Are you sure to delete the image?", async (result) => {
        if (result) {
          await this.deleteTemplateImage({
            id: id,
            templateId: this.template.id,
          })
            .then((res) => {
              this.$toastr.s("Image removed successfully.");
            })
            .catch((err) => {
              this.$toastr.e(err);
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
