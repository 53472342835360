import axios, * as others from '../../../axios'


const state={
    templates: [],
    template: {},
};
const getters = {
    templates: state => state.templates,
    template: state => state.template,
};

const actions={
    async fetchTemplates({commit}) {
        const response = await axios.get('/templates/').catch(function (error) {
            console.log(error);
        });

        commit('setTemplates',response.data);
    },
    async fetchTemplate({commit},id) {
        state.template = {};
        const response = await axios.get('/templates/'+id).catch(function (error) {
            console.log(error);
        });

        commit('setTemplate',response.data);
    },

    async storeTemplate({commit},formData){
        return new Promise((resolve, reject) => {
            axios.post('/elite-admin/templates', formData)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error.response)
                })
        })
    },
    async updateTemplate({commit},data) {

        return new Promise((resolve, reject) => {

            axios.post('/elite-admin/templates/'+data.id, data.formData)
                .then((response) => {   
                    commit('updateTemplate', response.data);

                    resolve({id: response.data.id});
                })
                .catch((error) => {

                    if (error.response){
                        reject(error.response);
                    }else{
                        console.log(error);
                    }

                });
        });

    },
    
    async deleteTemplate({commit},id) {

        return new Promise((resolve, reject) => {
            axios.delete('/elite-admin/templates/'+id)
                .then((response) => {
                    commit('deleteTemplate', id);
                    resolve(response.data);
                })
                .catch((error) => {

                    if (error.response){
                        reject(error.response);
                    }else{
                        console.log(error);
                    }
                });
        });

    },
    async deleteTemplateImage({commit},data) {

        return new Promise((resolve, reject) => {
            axios.delete('/elite-admin/images/'+data.id+'/templates/'+data.templateId)
                .then((response) => {
                    commit('deleteTemplateImage', data);
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response){
                        reject(error.response);
                    }else{
                        console.log(error);
                    }
                });
        });

    },
    setTemplateAdvert({commit},advert) {
        console.log(advert);
        commit('setShopAdvert',advert);
    },
    removeTemplateAdvert({commit}) {
        commit('removeShopAdvert');
    },
};

const mutations={
    setTemplates: (state,templates) => state.templates = templates,
    setTemplate: (state,template) => state.template = template,
    setShopAdvert: (state,advert) => state.template.advert = advert,
    removeShopAdvert: (state) => state.template.advert = null,
    updateTemplate: (state,template) => {
        const i = state.templates.map(template => template.id).indexOf(template.id);

        state.templates.splice(i,1);
        state.templates.splice(i,0,template);
    },
    deleteTemplate: (state,id)=>{
        const i = state.templates.map(template => template.id).indexOf(id);
        state.templates.splice(i,1);
    },
    deleteTemplateImage: (state,data)=>{

                const i = state.template.images.map(image=>image.id).indexOf(data.id);
                state.template.images.splice(i,1);


    },
};


const Module = {
    state,
    mutations,
    actions,
    getters
};

export default Module;
