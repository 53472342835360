import axios, * as others from '../../../axios'
import * as Vue from "vue";


const state={
    categories: [],
    category: {},
}
const mutations={
    UPDATE_CATEGORIES_LIST(state,payload)
    {
        state.categories=payload;
    }, UPDATE_CATEGORY(state,category)
    {
        state.category=category;
    },
    setCategoryAdvert: (state,advert) => state.category.advert = advert,
    removeCategoryAdvert: (state) => state.category.advert = null,
}
const actions={
    fetchCategories({commit},payload){
        console.log(payload)
        axios.get("/elite-admin/categories/"+payload).then((response)=>
        {
            commit('UPDATE_CATEGORIES_LIST',response.data)
        })
    },

    fetchCategory({commit},id){
        state.category = {};
        axios.get("/elite-admin/categories/"+id).then((response)=>
        {
            commit('UPDATE_CATEGORY',response.data)
        })
    },

    fetchShopCategories({commit},shopId){
        axios.get('/elite-admin/shops/'+shopId+'/categories').then((response)=>{
            commit('UPDATE_CATEGORIES_LIST',response.data);
        })
    },

    setCategoryAdvert({commit},advert) {
        commit('setCategoryAdvert',advert);
    },

    removeCategoryAdvert({commit}) {
        commit('removeCategoryAdvert');
    },

}
const getters={
    categories:state=>state.categories,
    category:state=>state.category,

}

const categoryModule = {
    state,
    mutations,
    actions,
    getters
}

export default categoryModule;