import axios, * as others from "../../../axios";
import axiosOg, * as otherAxios from "axios";
import router from '../../../router'

import csrf from "../../../api/csrf";
import jwt from "jsonwebtoken";

const state = {
  user: {},
  token: {},
  authenticated: false,
  axiosJson: {},
  axiosFile: {},
  apiUrl: "",
  serverUrl: "",
};
const mutations = {
  SET_AUTHENTICATED(state, value) {
    state.authenticated = value;
  },
  SET_USER(state, value) {
    state.user = value;
  },
  SET_TOKEN(state, value) {
    state.token = value;
  },
  SET_AXIOS_JSON(state, value) {
    state.axiosJson = value;
  },
  SET_AXIOS_FILE(state, value) {
    state.axiosFile = value;
  },
  SET_API_URL(state) {
    state.apiUrl =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_PROD_BASE_API_URL
        : process.env.VUE_APP_PROD_BASE_API_URL_LOCAL
        ? process.env.VUE_APP_PROD_BASE_API_URL_LOCAL
        : process.env.VUE_APP_DEV_BASE_API_URL;
  },
  SET_SERVER_URL(state) {
    state.serverUrl =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_PROD_BASE_URL
        : process.env.VUE_APP_PROD_BASE_URL_LOCAL
        ? process.env.VUE_APP_PROD_BASE_URL_LOCAL
        : process.env.VUE_APP_DEV_BASE_URL;
  },
  REMOVE_AUTH_DATA(state) {
    state.token = {};
    state.axiosFile = {};
    state.axiosJson = {};
    state.authenticated = false;
  },
};

const actions = {
  async bootstrap({ commit, dispatch }) {
    await dispatch("checkIfAuthenticated")
    dispatch("setAccessToken");
    dispatch("setAxiosJson");
    dispatch("setAxiosFile");
    commit("SET_API_URL");
    commit("SET_SERVER_URL");
  },

  async setAccessToken({ commit }) {
    const unParsedToken = localStorage.getItem("batelew-token");

    if (!unParsedToken) {
      return null;
    }

    commit("SET_AUTHENTICATED", true);
    const tokenData = JSON.parse(unParsedToken);

    // const tokenData = jwt.decode(token)

    commit("SET_TOKEN", tokenData.token);
    commit("SET_USER", tokenData.user);
  },

  async setAxiosJson({ commit }) {
    let axiosJson = axiosOg.create({
      baseURL:
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_PROD_BASE_API_URL
          : process.env.VUE_APP_PROD_BASE_API_URL_LOCAL
          ? process.env.VUE_APP_PROD_BASE_API_URL_LOCAL
          : process.env.VUE_APP_DEV_BASE_API_URL,
      headers: {
        Authorization: "Bearer " + state.token,
      },
    });

    axiosJson.defaults.withCredentials = true;

    commit("SET_AXIOS_JSON", axios);
  },

  async setAxiosFile({ commit }) {
    let axiosFile = axiosOg.create({
      baseURL:
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_PROD_BASE_URL
          : process.env.VUE_APP_PROD_BASE_URL_LOCAL
          ? process.env.VUE_APP_PROD_BASE_URL_LOCAL
          : process.env.VUE_APP_DEV_BASE_URL,

      headers: {
        Authorization: "Bearer " + state.token,
        "Content-Type": "multipart/form-data",
      },
    });

    axiosFile.defaults.withCredentials = true;

    commit("SET_AXIOS_FILE", axios);
  },

  async checkIfAuthenticated({ commit, state }) {
    await axios
      .get("elite-admin")
      .then((response) => {
        
      })
      .catch((error) => {
        localStorage.removeItem("batelew-token")
        router.push('/login');
      });
  },

  async login({ commit, state, dispatch }, form) {
    //await csrf.getCookie();

    return new Promise((resolve, reject) => {
      axios
        .post("/elite-admin/login", form)
        .then((response) => {
          //const token = jwt.sign({data: response.data},'batelew-hash')
          localStorage.setItem("batelew-token", JSON.stringify(response.data));
          location.reload();
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/elite-admin/logout")
        .then((response) => {
          localStorage.removeItem("batelew-token");
          commit("REMOVE_AUTH_DATA");
          location.reload();
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async register({ commit }, form) {
    //await csrf.getCookie();

    return new Promise((resolve, reject) => {
      axios
        .post("/elite-admin/register", form)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getTemplates({ commit }) {
    //await csrf.getCookie();

    return new Promise((resolve, reject) => {
      axios
        .get("/templates")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters = {
  authenticated(state) {
    return state.authenticated;
  },

  user(state) {
    return state.user;
  },

  token(state) {
    return state.token;
  },

  axiosJson(state) {
    return state.axiosJson;
  },
  axiosFile(state) {
    return state.axiosFile;
  },
  apiUrl(state) {
    return state.apiUrl;
  },
  serverUrl(state) {
    return state.serverUrl;
  },
};

const authModule = {
  state,
  mutations,
  actions,
  getters,
};

export default authModule;
