import axios, * as others from '../../../axios'


const state={

};
const getters = {

};

const actions={
    async storeAdvert({commit},formData) {
        return new Promise((resolve,reject)=>{
            axios.post('/elite-admin/adverts/', formData)
                .then((response)=>{
                    resolve(response);
                })
                .catch((error)=>{
                    reject(error.response.data);
                })
        });
    },

    async removeAdvert({commit},id){
        return new Promise((resolve,reject)=>{
            axios.delete('/elite-admin/adverts/'+id)
                .then((response)=>{
                    resolve(response);
                })
                .catch((error)=>{
                    reject(error.response.data);
                })
        });
    }
};

const mutations={

};


const Module = {
    state,
    mutations,
    actions,
    getters
};

export default Module;
