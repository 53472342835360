<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 v-if="id" class="m-0 text-dark">Edit Shop Owner Form</h1>
            <h1 v-else class="m-0 text-dark">Add Shop Owner Form</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="/elite_admin_home">Home</a>
              </li>
              <li v-if="id" class="breadcrumb-item active">Edit Shop Owner</li>
              <li v-else class="breadcrumb-item active">Add Shop Owner</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-info">
              <div class="card-header">
                <h3 v-if="id" class="card-title">Edit Shop Owner</h3>
                <h3 v-else class="card-title">Add Shop Owner</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form
                v-on:submit.prevent="submitForm"
                class="form-horizontal needs-validation"
              >
                <div class="card-body">
                  <div class="form-group row">
                    <label for="inputName" class="col-form-label">Name</label>

                    <input
                      type="text"
                      v-model="name"
                      class="form-control"
                      :class="errors.name ? 'is-invalid' : ''"
                      id="inputName"
                      placeholder="Name"
                    />
                    <div class="invalid-feedback">
                      <span v-if="errors.name && errors.name[0]">{{
                        errors.name[0]
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEmail" class="col-form-label">Email</label>

                    <input
                      type="email"
                      v-model="email"
                      class="form-control"
                      id="inputEmail"
                      placeholder="Email"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="inputPassword" class="col-form-label"
                      >Password</label
                    >

                    <input
                      type="password"
                      v-model="password"
                      class="form-control"
                      :class="errors.password ? 'is-invalid' : ''"
                      id="inputPassword"
                      placeholder="Password"
                    />
                    <div class="invalid-feedback">
                      <span v-if="errors.password && errors.password[0]">{{
                        errors.password[0]
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputPassword1" class="col-form-label"
                      >Confirm Password</label
                    >

                    <input
                      type="password"
                      v-model="password_confirmation"
                      class="form-control"
                      id="inputPassword1"
                      placeholder="Confirm"
                    />
                  </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                  <button class="btn btn-info">Submit</button>
                </div>
                <!-- /.card-footer -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ShopAdd",
  data() {
    return {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      errors: [],
    };
  },
  computed: {
    ...mapGetters(["shopOwner","displayErrorMessages"]),
  },
  props: {
    id: {
      default: false,
    },
  },
  mounted() {
    if (this.id) {
      this.fetchShopOwner(this.id);
    }
    this.setData();
  },
  watch: {
    shopOwner() {
      if (this.shopOwner.id) {
        this.setData();
      } else {
        this.resetData();
      }
    },
  },
  methods: {
    ...mapActions(["storeShopOwner", "fetchShopOwner", "updateShopOwner"]),
    async submitForm() {
      let formData = new FormData();

      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("password", this.password);
      formData.append("password_confirmation", this.password_confirmation);
      if (this.id) {
        formData.append("_method", "PUT");
        await this.updateShopOwner({ formData: formData, id: this.id })
          .then((res) => {
            this.$router.push("/show/shop-owner/" + this.id).catch((err) => {});
          })
          .catch((error) => {
            this.errors = this.displayErrorMessages(error);
          });
      } else {
        await this.storeShopOwner(formData)
          .then((res) => {
            this.$router
              .push("/show/shop-owner/" + res.data.id)
              .catch((err) => {});
          })
          .catch((error) => {
            this.errors = this.displayErrorMessages(error);
          });
      }
    },
    async setData() {
      if (this.id && this.shopOwner.id) {
        this.name = this.shopOwner.name;
        this.email = this.shopOwner.email;
      }
    },
    resetData() {
      this.name = "";
      this.email = "";
      this.password_confirmation = "";
      this.password = "";
    },
  },
};
</script>

<style scoped></style>
