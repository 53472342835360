import axios, * as others from '../../../axios'


const state={
    shops: [],
    shop: {},
};
const getters = {
    shops: state => state.shops,
    shop: state => state.shop,
};

const actions={
    async fetchShops({commit}) {
        const response = await axios.get('/elite-admin/shops/').catch(function (error) {
            console.log(error);
        });

        commit('setShops',response.data);
    },
    async fetchShop({commit},id) {
        state.shop = {};
        const response = await axios.get('/elite-admin/shops/'+id).catch(function (error) {
            console.log(error);
        });

        commit('setShop',response.data);
    },

    async storeShop({commit},formData){
        return new Promise((resolve, reject) => {
            axios.post('/elite-admin/shops', formData)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response)
                })
        })
    },

    async updateShop({commit},props){
        return new Promise((resolve, reject) => {
            axios.post('/elite-admin/shops/'+props.id, props.formData)
                .then((response) => {
                    commit('updateShop', response.data);
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response)
                })
        })
    },

    async deleteShop({commit},id) {

        return new Promise((resolve, reject) => {
            axios.delete('/elite-admin/shops/'+id)
                .then((response) => {
                    commit('deleteShop', id);
                    resolve(response.data);
                })
                .catch((error) => {

                    if (error.response){
                        reject(error.response);
                    }else{
                        console.log(error);
                    }
                });
        });

    },

    setShopAdvert({commit},advert) {
        console.log(advert);
        commit('setShopAdvert',advert);
    },
    removeShopAdvert({commit}) {
        commit('removeShopAdvert');
    },
};

const mutations={
    setShops: (state,shops) => state.shops = shops,
    setShop: (state,shop) => state.shop = shop,
    setShopAdvert: (state,advert) => state.shop.advert = advert,
    removeShopAdvert: (state) => state.shop.advert = null,
    updateShop: (state,shop) => {
        const i = state.shops.map(shop => shop.id).indexOf(shop.id);

        state.shops.splice(i,1);
        state.shops.splice(i,0,shop);
    },
    deleteShop: (state,id)=>{
        const i = state.shops.map(shop => shop.id).indexOf(id);
        state.shops.splice(i,1);
    },
};


const Module = {
    state,
    mutations,
    actions,
    getters
};

export default Module;
