import axios, * as others from '../../../axios'


const state={
    shopOwners: [],
    shopOwner: {},
};
const getters = {
    shopOwners: state => state.shopOwners,
    shopOwner: state => state.shopOwner,
};

const actions={
    async fetchShopOwners({commit}) {
        const response = await axios.get('/elite-admin/shop-owners/').catch(function (error) {
            console.log(error);
        });

        commit('setShopOwners',response.data);
    },
    async fetchShopOwner({commit},id) {
        state.shopOwner = {};
        const response = await axios.get('/elite-admin/shop-owners/'+id).catch(function (error) {
            console.log(error);
        });

        commit('setShopOwner',response.data);
    },

    async storeShopOwner({commit},formData){
        return new Promise((resolve, reject) => {
            axios.post('/elite-admin/shop-owners', formData)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response)
                })
        })
    },

    async updateShopOwner({commit},props){
        return new Promise((resolve, reject) => {
            axios.post('/elite-admin/shop-owners/'+props.id, props.formData)
                .then((response) => {
                    commit('updateShopOwner', response.data);
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response)
                })
        })
    },

    async deleteShopOwner({commit},id) {

        return new Promise((resolve, reject) => {
            axios.delete('/elite-admin/shop-owners/'+id)
                .then((response) => {
                    commit('deleteShopOwner', id);
                    resolve(response.data);
                })
                .catch((error) => {

                    if (error.response){
                        reject(error.response);
                    }else{
                        console.log(error);
                    }
                });
        });

    },
    setShopOwnerAdvert({commit},advert) {
        console.log(advert);
        commit('setShopAdvert',advert);
    },
    removeShopOwnerAdvert({commit}) {
        commit('removeShopAdvert');
    },
};

const mutations={
    setShopOwners: (state,shopOwners) => state.shopOwners = shopOwners,
    setShopOwner: (state,shopOwner) => state.shopOwner = shopOwner,
    setShopAdvert: (state,advert) => state.shopOwner.advert = advert,
    removeShopAdvert: (state) => state.shopOwner.advert = null,
    updateShopOwner: (state,shopOwner) => {
        const i = state.shopOwners.map(shopOwner => shopOwner.id).indexOf(shopOwner.id);

        state.shopOwners.splice(i,1);
        state.shopOwners.splice(i,0,shopOwner);
    },
    deleteShopOwner: (state,id)=>{
        const i = state.shopOwners.map(shopOwner => shopOwner.id).indexOf(id);
        state.shopOwners.splice(i,1);
    },
};


const Module = {
    state,
    mutations,
    actions,
    getters
};

export default Module;
