<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Shop Owners List</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="/elite_admin_home">Home</a>
              </li>
              <li class="breadcrumb-item active">Shop Owners List</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Shop List
                  <span class="badge badge-info right">{{
                    filteredShopOwners.length
                  }}</span>
                </h3>

                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 150px">
                    <input
                      type="text"
                      v-model="searchString"
                      name="table_search"
                      class="form-control float-right"
                      placeholder="Search"
                    />

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-default">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Shop Owner</th>
                      <th>Email</th>
                      <th>Date Joined</th>
                      <th>Shops</th>
                      <th>Actions</th>
                      <!-- <th>Adverted</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="shopOwner in filteredShopOwners"
                      v-bind:key="shopOwner.id"
                    >
                      <td>
                        <router-link :to="'/show/shop-owner/' + shopOwner.id">{{
                          shopOwner.name
                        }}</router-link>
                      </td>
                      <td>{{ shopOwner.email }}</td>
                      <td>{{ getDateFromNow(shopOwner.created_at) }}</td>
                      <td>{{ shopOwner.shops.length }}</td>
                      <td>
                        <router-link
                          :to="'/edit/shop-owner/' + shopOwner.id"
                          class="btn btn-sm bg-teal"
                          ><i class="fas fa-edit"></i
                        ></router-link>
                        <button
                          class="btn btn-sm btn-danger"
                          v-on:click="removeShopOwner(shopOwner.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                      <!-- <td><span v-if="shopOwner.advert">Yes</span><span v-else>No</span></td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ShopList",
  data: function() {
    return {
      searchString: "",
    };
  },
  props: {
    fetchedShops: {
      required: false,
    },
  },
  computed: {
    ...mapGetters(["shopOwners", "getDateFromNow"]),
    filteredShopOwners() {
      if (this.fetchedShops) {
        return this.fetchedShops.filter((owner) => {
          return !owner.name
            .toUpperCase()
            .search(this.searchString.toUpperCase());
        });
      } else {
        return this.shopOwners.filter((owner) => {
          return !owner.name
            .toUpperCase()
            .search(this.searchString.toUpperCase());
        });
      }
    },
  },
  created() {
    this.fetchShopOwners();
  },
  methods: {
    ...mapActions(["fetchShopOwners", "deleteShopOwner"]),
    async removeShopOwner(id) {
      bootbox.confirm(
        "Are you sure to delete the shop owner and all its related data?",
        async (result) => {
          if (result) {
            await this.deleteShopOwner(id)
              .then((res) => {
                this.$toastr.s("Shop owner removed successfully.");
              })
              .catch((err) => {
                this.$toastr.e(err);
              });
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
