import axios, * as others from '../../../axios'


const state={
    products: [],
    searchedProducts: [],
    product: {},
}
const mutations={
    UPDATE_PRODUCTS_LIST(state,payload)
    {
        state.products=payload;
    },UPDATE_PRODUCT(state,product)
    {
        state.product=product;
    },
    UPDATE_SEARCHED_PRODUCTS_LIST(state,payload)
    {
        if(payload)
        {
            state.searchedProducts=payload;
        }
        else {
            state.searchedProducts=state.products
        }

    },
    setProductAdvert: (state,advert) => state.product.advert = advert,
    removeProductAdvert: (state) => state.product.advert = null,
}
const actions={
    fetchProducts({commit},payload){
        axios.get("/elite-admin/products/"+payload).then((response)=>
        {
            commit('UPDATE_PRODUCTS_LIST',response.data)
        })
    }, fetchProduct({commit},id){
        axios.get("/elite-admin/products/"+id).then((response)=>
        {
            commit('UPDATE_PRODUCT',response.data)
        })
    },
    fetchShopProducts({commit},shopId){
        axios.get('/elite-admin/shops/'+shopId+'/products').then((response)=>{
            commit('UPDATE_PRODUCTS_LIST',response.data);
        })
    },
    fetchCategoryProducts({commit},categoryId){
        axios.get('/elite-admin/categories/'+categoryId+'/products').then((response)=>{
            commit('UPDATE_PRODUCTS_LIST',response.data);
        })
    },
    searchProduct(context,[searchedData="",catagory=0])
    {
        let searchProducts=[...state.products]
        // console.log("SearchedData " + searchedData)
        // console.log("catagory " + catagory)
        // console.log(typeof searchedData)

        let searchList=searchProducts.filter(product=>product.category.id !== null &&(product.category.id === catagory || catagory == 0) &&
                                                product.name !== null &&
                                                product.name.toLowerCase().includes(searchedData.toLowerCase()))
         context.commit('UPDATE_SEARCHED_PRODUCTS_LIST',searchList)
    },
    setProductAdvert({commit},advert) {
        commit('setProductAdvert',advert);
    },
    removeProductAdvert({commit}) {
        commit('removeProductAdvert');
    },
}
const getters={
    products:state=>state.products,
    product:state=>state.product,
    getProductFromId: (state)=> (id)=>{
        return state.products.find(product => product.id===id)
    },
    searchedProducts:state=>state.searchedProducts,

}

const productModule = {
    state,
    mutations,
    actions,
    getters
}

export default productModule;