<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Customer List</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="/elite_admin_home">Home</a>
              </li>
              <li class="breadcrumb-item active">Customer List</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Customer List
                  <span class="badge badge-info right">{{
                    filteredCustomers.length
                  }}</span>
                </h3>

                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <input
                      type="text"
                      name="table_search"
                      v-model="searchString"
                      class="form-control float-right"
                      placeholder="Search"
                    />

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-default">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <!-- <th>Orders</th> -->
                      <th>Date Joined</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="customer in filteredCustomers"
                      v-bind:key="customer.id"
                    >
                      <td>
                        <router-link :to="'/show/customer/' + customer.id">{{
                          customer.name
                        }}</router-link>
                      </td>
                      <td>{{ customer.email }}</td>
                      <!-- <td>{{customer.number_of_orders}}</td> -->
                      <td>{{ getDate(customer.created_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CustomerList",
  data: function() {
    return {
      searchString: "",
    };
  },
  computed: {
    ...mapGetters(["customers"]),
    filteredCustomers() {
      return this.customers.filter((customer) => {
        return !customer.name
          .toUpperCase()
          .search(this.searchString.toUpperCase());
      });
    },
  },
  created() {
    this.fetchCustomers();
  },
  methods: {
    ...mapActions(["fetchCustomers"]),
    getDate(date) {
      return moment(date)
        .add(3, "hour")
        .fromNow();
    },
  },
};
</script>

<style scoped></style>
