<template>
  <div v-if="shopOwner.id">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Shop Owner</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <a href="/elite_admin_home">Home</a>
                </li>
                <li class="breadcrumb-item active">
                  <router-link :to="'/list/shop-owner'"
                    >Shop Owners</router-link
                  >
                </li>
                <li class="breadcrumb-item active">{{ shopOwner.name }}</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <!-- Widget: user widget style 2 -->
              <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-dark">
                  <div class="widget-user-image">
                    <img
                      class="img-circle elevation-2"
                      src="/img/store.png"
                      alt="User Avatar"
                    />
                  </div>
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">{{ shopOwner.name }}</h3>
                  <h5 class="widget-user-desc">Shop Owner</h5>
                </div>
                <div class="card-footer p-0">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">ID</span>
                        <span class="float-right">{{ shopOwner.id }}</span>
                      </span>
                    </li>
                     <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Name</span>
                        <span class="float-right">{{ shopOwner.name }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Email</span>
                        <span class="float-right ">{{ shopOwner.email }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Shops</span>
                        <span class="float-right badge bg-primary"
                          ><span v-if="shopOwner.shops">{{
                            shopOwner.shops.length
                          }}</span
                          ><span v-else>0</span></span
                        >
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Date Joined</span>
                        <span class="float-right ">{{
                          getDate(shopOwner.created_at)
                        }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ShopList :fetched-shops="shopOwner.shops"></ShopList>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ShopList from "./ShopList";

export default {
  data() {
    return {};
  },
  name: "ShopShow",
  components: {
    ShopList,
  },
  computed: {
    ...mapGetters(["shopOwner", "getDate", "serverUrl"]),
  },
  props: {
    id: {
      required: true,
    },
  },
  async created() {
    await this.fetchShopOwner(this.id);
  },
  methods: {
    ...mapActions([
      "fetchShopOwner",
      //   "setShopOwnerAdvert",
      //   "removeShopOwnerAdvert",
    ]),
  },
};
</script>

<style scoped></style>
