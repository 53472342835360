<template>
  <div v-if="shop.id">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Shop</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <a href="/elite_admin_home">Home</a>
                </li>
                <li class="breadcrumb-item active">
                  <router-link :to="'/list/shop'">Shop List</router-link>
                </li>
                <li class="breadcrumb-item active">{{ shop.shop_name }}</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <!-- Widget: user widget style 2 -->
              <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-dark">
                  <div class="widget-user-image">
                    <img
                      class="img-circle elevation-2"
                      src="/img/store.png"
                      alt="User Avatar"
                    />
                  </div>
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">{{ shop.shop_name }}</h3>
                  <h5 class="widget-user-desc">Batelew Shop</h5>
                </div>
                <div class="card-footer p-0">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Shop Id</span>
                        <span class="float-right">{{ shop.id }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Shop Name</span>
                        <span class="float-right">{{ shop.shop_name }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Shop Owner</span>
                        <span class="float-right">{{
                          shop.shopOwner.name
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Url</span>
                        <span class="float-right"
                          >{{ shop.shop_url }}.batelew.com</span
                        >
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Categories</span>
                        <span class="float-right badge bg-primary">{{
                          shop.number_of_categories
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Products</span>
                        <span class="float-right badge bg-primary">{{
                          shop.number_of_products
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Email</span>
                        <span class="float-right">{{ shop.email }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Date Joined</span>
                        <span class="float-right">{{
                          getDate(shop.created_at)
                        }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.widget-user -->
            </div>

            <AdvertInfo
              :type="'shop'"
              :advertable="shop"
              @set-advert="setShopAdvert"
              @remove-advert="removeShopAdvert"
            ></AdvertInfo>

            <div class="col-lg-4 col-6">
              <!-- small box -->
              <div class="small-box bg-info">
                <div class="inner">
                  <h3>150</h3>

                  <p>New Orders</p>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a href="#" class="small-box-footer"
                  >More info <i class="fas fa-arrow-circle-right"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CategoryList :shop-id="this.id"></CategoryList>

      <ProductList :shop-id="this.id"></ProductList>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CategoryList from "./CategoryList";
import ProductList from "./ProductList";
import AdvertInfo from "./AdvertInfo";

export default {
  name: "ShopShow",
  components: {
    CategoryList,
    ProductList,
    AdvertInfo,
  },
  computed: {
    ...mapGetters(["shops", "shop", "getDate", "serverUrl"]),
  },
  props: {
    id: {
      required: true,
    },
  },
  async created() {
    await this.fetchShop(this.id);

    //console.log(this.getShop(this.id));
  },
  methods: {
    ...mapActions(["fetchShop", "setShopAdvert", "removeShopAdvert"]),
  },
};
</script>

<style scoped></style>
