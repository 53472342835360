<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Product List
                <span class="badge badge-info right">{{
                  filteredProducts.length
                }}</span>
              </h3>

              <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 150px;">
                  <input
                    type="text"
                    v-model="searchString"
                    name="table_search"
                    class="form-control float-right"
                    placeholder="Search"
                  />

                  <div class="input-group-append">
                    <button type="submit" class="btn btn-default">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Added at</th>
                    <th v-if="filteredProducts[0] && filteredProducts[0].category">Category Name</th>
                    <th>Adverted</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="product in filteredProducts"
                    v-bind:key="product.id"
                  >
                    <td>
                      <router-link :to="'/show/product/' + product.id">{{
                        product.name
                      }}</router-link>
                    </td>
                    <td>{{ product.price }}</td>
                    <td>{{ getDate(product.created_at) }}</td>
                    <td v-if="product.category">{{ product.category.name }}</td>
                    <td>
                      <span v-if="product.advert">Yes</span
                      ><span v-else>No</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProductList",
  data: function() {
    return {
      searchString: "",
    };
  },
  props: {
    shopId: {
      default: "",
    },
    categoryId: {
      default: "",
    },
  },
  async created() {
    if (this.shopId) {
      await this.fetchShopProducts(this.shopId);
    } else {
      await this.fetchCategoryProducts(this.categoryId);
    }
  },
  computed: {
    ...mapGetters(["products", "getDate"]),
    filteredProducts() {
      return this.products.filter((product) => {
        return !product.name
          .toUpperCase()
          .search(this.searchString.toUpperCase());
      });
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["fetchShopProducts", "fetchCategoryProducts"]),
  },
};
</script>

<style scoped></style>
