import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueToastr from "vue-toastr";


Vue.config.productionTip = false

Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});

new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    this.$store.dispatch('bootstrap');
  }
}).$mount('#app')
