<template>
  <div v-if="category.id">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">Category</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <a href="/elite_admin_home">Home</a>
                </li>
                <li class="breadcrumb-item active" v-if="category.shopOwner">
                  <router-link :to="'/show/shop/' + category.shopOwner.id"
                    >Shop</router-link
                  >
                </li>
                <li class="breadcrumb-item active">{{ category.name }}</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <!-- Widget: user widget style 2 -->
              <div class="card card-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-dark">
                  <div class="widget-user-image">
                    <img
                      v-if="category.images[0]"
                      class="img-circle elevation-2"
                      :src="serverUrl + category.images[0].storageUrl"
                      alt="Category Image"
                    />
                    <img
                      v-else
                      class="img-circle elevation-2"
                      src=""
                      alt="Category Image"
                    />
                  </div>
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">{{ category.name }}</h3>
                </div>
                <div class="card-footer p-0">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Category ID</span>
                        <span class="float-right">{{ category.id }}</span>
                      </span>
                    </li>

                     <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Category Name</span>
                        <span class="float-right">{{ category.name }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold" v-if="shop.id">Shop</span>
                        <span class="float-right">{{ shop.shop_name }}</span>
                      </span>
                    </li>

                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Products</span>
                        <span class="float-right badge bg-primary">{{
                          category.number_of_products
                        }}</span>
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Has Quantity</span>
                        <span class="float-right "
                          ><span v-if="category.has_quantity">Yes</span
                          ><span v-else>No</span></span
                        >
                      </span>
                    </li>
                    <li class="nav-item">
                      <span href="#" class="nav-link">
                        <span class="text-bold">Date Added</span>
                        <span class="float-right ">{{
                          getDate(category.created_at)
                        }}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.widget-user -->
            </div>
            <AdvertInfo
              :type="'category'"
              :advertable="category"
              :selected-advert="category.advert"
              @set-advert="setCategoryAdvert"
              @remove-advert="removeCategoryAdvert"
            ></AdvertInfo>
            <div class="col-lg-4 col-6">
              <!-- small box -->
              <div class="small-box bg-info">
                <div class="inner">
                  <h3>150</h3>

                  <p>New Orders</p>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a href="#" class="small-box-footer"
                  >More info <i class="fas fa-arrow-circle-right"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ProductList v-if="id" :category-id="id"></ProductList>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductList from "./ProductList";
import AdvertInfo from "./AdvertInfo";

export default {
  name: "CategoryShow",
  components: {
    ProductList,
    AdvertInfo,
  },
  computed: {
    ...mapGetters(["shop", "category", "getDate", "serverUrl"]),
  },
  props: {
    id: {
      required: true,
    },
  },
  async created() {
    await this.fetchCategory(this.id);
  },
  methods: {
    ...mapActions([
      "fetchCategory",
      "setCategoryAdvert",
      "removeCategoryAdvert",
    ]),
  },
};
</script>

<style scoped></style>
