<template>
    <div>
        <!-- Navbar -->
        <nav class="main-header navbar navbar-expand navbar-white navbar-light">
            <!-- Left navbar links -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
                </li>
                <li class="nav-item d-none d-sm-inline-block">
                    <a href="#" v-on:click.prevent='logout' class="nav-link">Logout</a>
                </li>
            </ul>


            <!-- SEARCH FORM -->
            <form class="form-inline ml-3">
                <div class="input-group input-group-sm">
                    <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
                    <div class="input-group-append">
                        <button class="btn btn-navbar" type="submit">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>

            <div id="flash-message"></div>

            <!-- Right navbar links -->
            <ul class="navbar-nav ml-auto">
                <!-- Notifications Dropdown Menu -->
                <li class="nav-item dropdown">
                    <a class="nav-link" data-toggle="dropdown" href="#">
                        <i class="far fa-bell"></i>
                        <span class="badge badge-warning navbar-badge">0</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span class="dropdown-item dropdown-header">15 Notifications</span>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">
                            <i class="fas fa-envelope mr-2"></i> 4 new messages
                            <span class="float-right text-muted text-sm">3 mins</span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">
                            <i class="fas fa-users mr-2"></i> 8 friend requests
                            <span class="float-right text-muted text-sm">12 hours</span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">
                            <i class="fas fa-file mr-2"></i> 3 new reports
                            <span class="float-right text-muted text-sm">2 days</span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
                    </div>
                </li>

            </ul>
        </nav>
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="/elite_admin_home" class="brand-link">
                <img src="/images/logo-w.png" alt="Batelew Logo" class="brand-image img-circle elevation-3"
                     style="opacity: .8">
                <span class="brand-text font-weight-light">Batelew</span>
            </a>

            <!-- Sidebar -->
            <div class="sidebar">
                <!-- Sidebar Menu -->
                <nav class="mt-2">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <!-- Add icons to the links using the .nav-icon class
                             with font-awesome or any other icon font library -->
                        <li class="nav-item has-treeview">
                            <a class="nav-link">
                                <i class="nav-icon fas fa-chart-pie"></i>
                                <p>
                                    Statistics
                                    <i class="fas fa-angle-left right"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <router-link :to="'/stats/order'">
                                        <a  class="nav-link">
                                            <i class="fas fa-shopping-cart nav-icon"></i>
                                            <p>Orders</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="'/stats/shop'">
                                        <a  class="nav-link">
                                            <i class="fas fa-store-alt nav-icon"></i>
                                            <p>Shops</p>
                                        </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="'/stats/customer'">
                                        <a class="nav-link">
                                            <i class="nav-icon fas fa-user-alt"></i>
                                            <p>
                                                Customers
                                            </p>
                                        </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item has-treeview menu-open">
                            <a class="nav-link">
                                <i class="nav-icon fas fa-users"></i>
                                <p>
                                    Shop Owners
                                    <i class="fas fa-angle-left right"></i>
                                    <!-- <span class="badge badge-info right">{{shopOwners.length}}</span> -->
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <router-link :to="'/list/shop-owner'">
                                    <a class="nav-link">
                                        <i class="far fa-list-alt nav-icon"></i>
                                        <p>List</p>
                                    </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="'/add/shop-owner'">
                                    <a class="nav-link">
                                        <i class="far fa-plus-square nav-icon"></i>
                                        <p>Add</p>
                                    </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item has-treeview menu-open">
                            <a class="nav-link">
                                <i class="nav-icon fas fa-store-alt"></i>
                                <p>
                                    Shops
                                    <i class="fas fa-angle-left right"></i>
                                    <!-- <span class="badge badge-info right">{{shops.length}}</span> -->
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <router-link :to="'/list/shop'">
                                    <a class="nav-link">
                                        <i class="far fa-list-alt nav-icon"></i>
                                        <p>List</p>
                                    </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="'/add/shop'">
                                    <a class="nav-link">
                                        <i class="far fa-plus-square nav-icon"></i>
                                        <p>Add</p>
                                    </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item has-treeview menu-open">
                            <a class="nav-link">
                                <i class="nav-icon fas fa-store"></i>
                                <p>
                                    Templates
                                    <i class="fas fa-angle-left right"></i>
                                    <!-- <span class="badge badge-info right">{{templates.length}}</span> -->
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <router-link :to="'/list/template'">
                                    <a class="nav-link">
                                        <i class="far fa-list-alt nav-icon"></i>
                                        <p>List</p>
                                    </a>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="'/add/template'">
                                    <a class="nav-link">
                                        <i class="far fa-plus-square nav-icon"></i>
                                        <p>Add</p>
                                    </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item has-treeview menu-open">
                            <a class="nav-link">
                                <i class="nav-icon fas fa-users"></i>
                                <p>
                                    Customers
                                    <i class="fas fa-angle-left right"></i>
                                    <!-- <span class="badge badge-info right">{{customers.length}}</span> -->
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <router-link :to="'/list/customer'">
                                    <a  class="nav-link">
                                        <i class="far fa-list-alt nav-icon"></i>
                                        <p>List</p>
                                    </a>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <!-- /.sidebar-menu -->
            </div>
            <!-- /.sidebar -->
        </aside>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "AdminLayout",
        props: [],
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters([
                'shopOwners','shops','customers'
            ]),
            templates(){
                return 0;
            }

        },
        watch:{

        },
        methods: {
            ...mapActions([
                'logout'
            ]),
            search()
            {


            },
        }
    }
</script>
