<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">
                Category List
                <span class="badge badge-info right">{{
                  filteredCategories.length
                }}</span>
              </h3>

              <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 150px;">
                  <input
                    type="text"
                    v-model="searchString"
                    name="table_search"
                    class="form-control float-right"
                    placeholder="Search"
                  />

                  <div class="input-group-append">
                    <button type="submit" class="btn btn-default">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Has Quantity</th>
                    <th>Added at</th>
                    <th>Products</th>
                    <th>Adverted</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="category in filteredCategories"
                    v-bind:key="category.id"
                  >
                    <td>
                      <router-link :to="'/show/category/' + category.id">{{
                        category.name
                      }}</router-link>
                    </td>
                    <td>{{ category.has_quantity ? true : false }}</td>
                    <td>{{ getDate(category.created_at) }}</td>
                    <td v-if="category.products">
                      {{ category.products.length }}
                    </td>
                    <td>
                      <span v-if="category.advert">Yes</span
                      ><span v-else>No</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CategoryList",
  data: function() {
    return {
      searchString: "",
    };
  },
  props: {
    shopId: {
      required: true,
    },
  },
  watch: {
    shopId() {
      this.fetchShopCategories(this.shopId);
    },
  },
  async created() {
    if (this.shopId) {
      await this.fetchShopCategories(this.shopId);
    }
  },
  computed: {
    ...mapGetters(["categories", "getDate"]),
    filteredCategories() {
      return this.categories.filter((category) => {
        return !category.name
          .toUpperCase()
          .search(this.searchString.toUpperCase());
      });
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["fetchShopCategories"]),
  },
};
</script>

<style scoped></style>
