import axios, * as others from '../../../axios'


const state={
    customers: [],
    customer: {},
};
const getters = {
    customers: state => state.customers,
    customer: state => state.customer,
};

const actions={
    async fetchCustomers({commit}) {
        const response = await axios.get('/elite-admin/customers/').catch(function (error) {
            console.log(error);
        });

        commit('setCustomers',response.data);
    },
    async fetchCustomer({commit},id) {

        const response = await axios.get('/elite-admin/customers/'+id).catch(function (error) {
            console.log(error);
        });

        commit('setCustomer',response.data);
    }
};

const mutations={
    setCustomers: (state,customers) => state.customers = customers,
    setCustomer: (state,customer) => state.customer = customer,
};


const Module = {
    state,
    mutations,
    actions,
    getters
};

export default Module;